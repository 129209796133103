import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'utils/parseCost';

export default class ItemConcepImports extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { label, amount } = this.props;

    return amount > 0 ? <li className="clearfix"><span className="col col-title">{label}</span><span className="col"><strong>$ {format(amount, 2)}</strong></span></li> : null;
  }

}

ItemConcepImports.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired
};
