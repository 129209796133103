import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TableFormasPagos extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { references } = this.props;

    return (
      <div className="col-lg-6 col-md-6 col-sm-6">
        <legend>Formas de pago</legend>
        <table className="table">
          <thead className="cart-header">
            <tr>
              <th scope="col">Banco</th>
              <th scope="col">Referencia de Pago</th>
            </tr>
          </thead>
          <tbody>
            {
              references.map((r, i) => {
                const { bancoId: { nombre }, referencia } = r;

                return (
                  <tr key={i}>
                    <td>{nombre}</td>
                    <td>{referencia}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }

}

TableFormasPagos.propTypes = {
  loading: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  references: PropTypes.array.isRequired,
  handleOxxo: PropTypes.func.isRequired
};
