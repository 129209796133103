import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';

export default class PopOver extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { id, trigger, align, title, popover, children } = this.props;

    return (
      <OverlayTrigger trigger={trigger} placement={align}
        overlay={
          <Popover id={id} title={title}>
            {popover}
          </Popover>
        }>
        {children}
      </OverlayTrigger>
    );
  }

}

PopOver.propTypes = {
  id:       PropTypes.string.isRequired,
  trigger:  PropTypes.array,
  align:    PropTypes.string,
  title:    PropTypes.string,
  popover:  PropTypes.node,
  children: PropTypes.node
};

PopOver.defaultProps = {
  align:    'top',
  trigger:  ['hover', 'focus']
};
