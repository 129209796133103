import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { getRequest } from 'utils/api';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

export default class FilterSelectSimple extends Component{

  constructor(props){
    super(props);

    this.state = {
      optionsSelected: {}
    };

    this.changeOptions = this.changeOptions.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  // reset
  componentDidUpdate(nextProps) {
    const { optionsSelected } = this.state;

    if ( optionsSelected !== null ) {
      if ( nextProps.value === '' && Object.getOwnPropertyNames(optionsSelected).length > 0 ) {
        this.setState({ optionsSelected: {} });
      }
    }
  }

  changeOptions(val){
    this.setState({ optionsSelected: val });
    this.props.onChangeOption(val);
  }

  loadData(input, callback){
    if( !input || input.trim().length < 3 ){
      callback([]);
      return;
    }

    getRequest({
      url: `${this.props.url}?query=${input}`,
      success: ({ data }) => {
        if( data.length > 0 ) {
          const options = this.renderOptions(data);
          callback(options);
        }else{
          callback([]);
        }
      },
      fail: (error, status, data) => {
        console.log(error, status, data);
      }
    });
  }

  renderOptions(data){
    var options = [];
    let fields = this.props.fields;
    const value = fields[0];
    const label = fields[1].split(',');

    data.map( option => {
      let tag = '';

      label.map(t => {
        tag += option[t];
      });

      options.push({ value: option[value], label: tag, row: option });
    });

    return options;
  }

  syncSelect(multi){
    return(
      <Select
        placeholder="Buscar..."
        name="options"
        value={this.state.optionsSelected}
        options={this.renderOptions(this.props.options)}
        onChange={this.changeOptions}
        multi={multi}
      />
    );
  }

  asyncSelect(){
    const { disabled } = this.props;
    const { optionsSelected } = this.state;

    return(
      <AsyncSelect
        placeholder="Buscar..."
        name="options"
        loadOptions={this.loadData}
        defaultOptions
        value={optionsSelected}
        isDisabled={disabled}
        onChange={this.changeOptions}
      />
    );
  }

  render(){
    const multi = this.props.multi;
    const render = this.props.async ? this.asyncSelect() : this.syncSelect(multi);

    return(
      <div>
        {render}
      </div>
    );
  }
}

FilterSelectSimple.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.node,
  options: PropTypes.array,
  onChangeOption: PropTypes.func,
  multi: PropTypes.bool,
  async: PropTypes.bool,
  url: PropTypes.string,
  fields: PropTypes.array
};

FilterSelectSimple.defaultProps = {
  multi: false,
  async: false,
  options: [],
  url: '',
  fields: ['_id', 'name']
};
