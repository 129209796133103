import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { pathFilesApi } from 'utils/api';

export default class IconDiet extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { image, style } = this.props;

    return (
      <img src={ pathFilesApi() + image} width="30px" height="30px" style={style} />
    );
  }

}

IconDiet.propTypes = {
  image: PropTypes.string,
  style: PropTypes.object
};
