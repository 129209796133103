import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DivTwice extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { classOne, classTwo, nodeOne, nodeTwo } = this.props;

    return (
      <div className="row">
        <div className={classOne}>
          {nodeOne}
        </div>
        <div className={classTwo}>
          {nodeTwo}
        </div>
      </div>
    );
  }

}

DivTwice.propTypes = {
  classOne: PropTypes.string,
  classTwo: PropTypes.string,
  nodeOne: PropTypes.node.isRequired,
  nodeTwo: PropTypes.node.isRequired
};

DivTwice.defaultProps = {
  classOne: 'col-xs-6 col-sm-6 col-md-6 col-lg-6',
  classTwo: 'col-xs-6 col-sm-6 col-md-6 col-lg-6'
};
