import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DivFormGroup } from 'controls';
import { Icon } from 'stateless';

export default class AdjuntImage extends Component{

  constructor(){
    super();
  }

  handleImageChange(event){
    event.preventDefault();

    const file = event.target.files[0];

    this.props.loadFile(file);
  }

  render(){
    return(
      <DivFormGroup>
        <input type="file" name="image" id="image" style={{display: 'none'}}
          ref={(ref) => this.image = ref}
          accept="image/jpg, image/jpeg, image/png"
          onChange={this.handleImageChange.bind(this)} />
        <button type="button" className="btn btn-primary" onClick={() => this.image.click()}>
          <Icon icon="picture" /> Selecciona una imagen
        </button>
      </DivFormGroup>
    );
  }
}

AdjuntImage.propTypes = {
  loadFile: PropTypes.func.isRequired
};
