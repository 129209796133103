import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RowInfoInformation extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { title, text } = this.props;

    return (
      <p className="card-text"><strong>{title}:</strong> {text === null ? '' : text}</p>
    );
  }

}

RowInfoInformation.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

RowInfoInformation.defaultProps = {
  text: ''
};
