import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'stateless';
// import { Icon, Space } from 'stateless';
import { postRequest, URL_FILES } from 'utils/api';
import { PUBLIC } from 'config/conekta';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from 'utils/payment';
import 'react-credit-cards/es/styles-compiled.css';
import { AlertDialog, LoadingProgress } from 'components/utils';
import { PageNotFound } from 'components/application/security';
import { TableFormasPagos, TableFacturas, ItemConcepImports, CreditCard, PayCompleted } from 'components/cb';
const TARJETA = 1;
const PREELABORADO = 2;

export default class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      load: false,
      data: null,
      total: 0,
      phone: '',
      email: '',
      number: '',
      name: '',
      expiry: '',
      year_expiry: '',
      month_expiry: '',
      cvc: '',
      issuer: '',
      focused: '',
      error: {
        visible: false,
        type: '',
        title: '',
        message: ''
      },
      listChecks: [],

      paymentLoading: false,
      paymentCompleted: false,
      payment: {
        clave: '',
        facturas: [],
        name: '',
        last4: '',
        brand: '',
        amount: ''
      },
      typePayment: 0,
      loading: false,
      checkAll: true
    };
    this.loadData = this.loadData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
    this.handlePaymentPreelaborado = this.handlePaymentPreelaborado.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const { params: { hashId } } = this.props;

    postRequest({
      url: 'edo',
      body: {
        hashId
      },
      success: ({ data }, status) => {
        if (status === 200) {
          const listChecks = [];
          data.list = data.list.filter(f => f.saldo > 0);
          data.list.sort((a, b) => a.fecha > b.fecha ? 1 : -1);
          data.list.map(f => {
            listChecks.push({
              id: f._id,
              checked: true
            });
          });
          let email = data.email;
          if (!email && data.contactos && data.contactos.length > 0) {
            email = data.contactos[0].email;
          }
          let phone = data.mobile;
          if (!phone && data.contactos && data.contactos.length > 0) {
            phone = data.contactos[0].phone;
          }

          this.setState({
            data,
            email,
            phone,
            total: data.total,
            listChecks
          });
        } else {
          this.setState({ load: true });
        }
      },
      fail: (error, status, data) => {
        console.log('fail', error, status, data);
        this.setState({ load: true });
        location.href = `/`;
      }
    });
  }

  onChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleCallback({ issuer }, isValid) {
    if (isValid) {
      this.setState({ issuer });
    }
  }

  handleInputFocus({ target }) {
    this.setState({
      focused: target.name,
    });
  }

  handleInputChange({ target }) {
    const name = target.name;
    let value = target.value;
    let { expiry } = this.state;
    const { year_expiry, month_expiry } = this.state;
    if (name === 'number') {
      value = formatCreditCardNumber(value);
    } else if (name === 'month_expiry') {
      expiry = formatExpirationDate(`${value.toString().padStart(2, '0')}${year_expiry}`);
    } else if (name === 'year_expiry') {
      expiry = formatExpirationDate(`${month_expiry.toString().padStart(2, '0')}${value}`);
    } else if (name === 'cvc') {
      value = formatCVC(target.value);
    }
    this.setState({ [name]: value, expiry });
  }

  onChangeCheckAll(e) {
    const checked = e.target.checked;
    const { listChecks, data: { list } } = this.state;
    listChecks.map((c) => {
      c.checked = checked;
    });

    const total = list.reduce((count, factura) => {
      const facturaSelected = listChecks.find(c => c.id === factura._id && c.checked);
      if (typeof facturaSelected !== 'undefined') {
        const unit_price = factura.saldo;
        if (factura.descuento_pago && factura.descuento_pago.percentage > 0) {
          const descuento = (factura.descuento_pago.percentage / 100) * unit_price;
          count += (factura.saldo - descuento);
        } else {
          count += unit_price;
        }
      }
      return count;
    }, 0);

    this.setState({ listChecks, total, checkAll: checked });
  }

  onChangeFacturas({ item, checked }) {
    if ((item === 0 && !checked) || (item > 0 && (checked || !checked))) {
      const { listChecks, data: { list } } = this.state;

      listChecks.map((c, i) => {
        if (checked || item === 0) {
          if (i > item) {
            c.checked = false;
          } else {
            c.checked = true;
          }
        } else {
          if (i >= item) {
            c.checked = false;
          } else {
            c.checked = true;
          }
        }
      });

      const total = list.reduce((count, factura) => {
        const facturaSelected = listChecks.find(c => c.id === factura._id && c.checked);
        if (typeof facturaSelected !== 'undefined') {
          const unit_price = factura.saldo;
          if (factura.descuento_pago && factura.descuento_pago.percentage > 0) {
            const descuento = (factura.descuento_pago.percentage / 100) * unit_price;
            count += (factura.saldo - descuento);
          } else {
            count += unit_price;
          }
        }
        return count;
      }, 0);

      this.setState({ listChecks, total });
    }
  }

  getTokenCard(callback) {
    const { name, number, expiry, cvc } = this.state;
    const e = expiry.split('/');
    const exp_year = parseInt(e[1]) + 2000;
    const exp_month = e[0];

    const card = { number, name, exp_year, exp_month, cvc };

    Conekta.setPublicKey(PUBLIC);

    Conekta.Token.create({ card }, (token) => {
      callback(true, token.id);
    }, (response) => {
      console.log('response', response);
      callback(false, null);
    });
  }

  cleanError() {
    this.setState({ error: { visible: false, type: '', title: '', message: '' } });
  }

  moved() {
    window.scrollTo(0, 0);
  }

  onClickDescargarReciboPDF(id) {
    const { params: { hashId } } = this.props;
    this.setState({ loading: true }, () => {
      postRequest({
        url: 'recibo/pdf',
        body: {
          id, hashId: hashId
        },
        success: ({ data: { url } }) => {
          window.open(URL_FILES + url, '_blank');
        },
        fail: () => { },
        finally: () => {
          this.setState({ loading: false });
        }
      });
    });
  }

  onClickDescargarPDF(id) {
    const { params: { hashId } } = this.props;
    this.setState({ loading: true }, () => {
      postRequest({
        url: 'pdf',
        body: {
          id, hashId: hashId
        },
        success: ({ data: { url } }) => {
          window.open(URL_FILES + url, '_blank');
        },
        fail: () => { },
        finally: () => {
          this.setState({ loading: false });
        }
      });
    });
  }

  onClickDescargarXML(id) {
    const { params: { hashId } } = this.props;
    this.setState({ loading: true }, () => {
      postRequest({
        url: 'xml',
        body: {
          id, hashId: hashId
        },
        success: ({ data: { url } }) => {
          window.open(URL_FILES + url, '_blank');
        },
        fail: () => { },
        finally: () => {
          this.setState({ loading: false });
        }
      });
    });
  }


  handlePayment(event) {
    event.preventDefault();

    this.getTokenCard((isValid, tokenId) => {
      if (isValid) {
        this.cleanError();

        this.setState({ paymentLoading: true });

        const { params: { hashId } } = this.props;
        const { total, listChecks, name, email, phone } = this.state;

        const list = listChecks.map(c => {
          if (c.checked) {
            return c.id;
          }
        });

        postRequest({
          url: 'orden',
          body: {
            tokenId, total, list, hashId, name, email, phone
          },
          success: ({ data }, status) => {
            if (status === 201) {
              this.moved();

              const { clave, facturas, name, last4, brand, amount } = data;

              this.setState({
                payment: {
                  clave, facturas, name, last4, brand, amount
                },
                paymentCompleted: true,
                paymentLoading: false
              });
            } else {
              this.setState({ paymentLoading: false });
            }
          },
          fail: (error, status, data) => {
            this.setState({
              load: true,
              paymentLoading: false,
              error: {
                visible: true,
                type: 'warning',
                title: 'Alerta',
                message: data.message
              }
            });
          }
        });

      } else {
        this.setState({
          error: {
            visible: true,
            type: 'warning',
            title: 'Error',
            message: 'Tarjeta inválida, favor de verificar los datos proporcionados.'
          }
        });
      }
    });
  }

  handleOxxo(event) {
    event.preventDefault();

    const { params: { hashId } } = this.props;
    const { total, listChecks } = this.state;

    const list = listChecks.map(c => {
      if (c.checked) {
        return c.id;
      }
    });
    console.log('list', list);
    this.setState({ loading: true }, () => {
      postRequest({
        url: 'oxxo',
        body: {
          total, list, hashId
        },
        success: ({ data: { url } }) => {
          window.open(URL_FILES + url, '_blank');
        },
        fail: () => {
        },
        finally: () => {
          this.setState({ loading: false });
        }
      });
    });
  }

  renderPayment() {
    const { data, listChecks, total, typePayment, error: { visible, type, title, message }, loading, checkAll } = this.state;

    const { params: { hashId } } = this.props;


    return (
      <Fragment>
        <section className="consult-section">
          <div className="auto-container">
            <div className="sec-title-2 light centered">
              <h2>PAGO EN LÍNEA</h2>
            </div>
          </div>
        </section>
        <section className="cart-section">
          <div className="form">
            <div className="auto-container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="sidebar-widget quote-widget">
                    <div className="widget-content">
                      <div className="upper-box">
                        <h3>{data.name}</h3>
                        <div className="touch"><b>RFC:</b> {data.rfc}</div>
                        <div className="touch"><b>DOMICILIO:</b> {data.address}</div>
                        <div className="touch">
                          <a href="/" style={{ background: '#007bff', color: '#fff', padding: '4px 10px' }}>Cerrar sesión</a>
                          <a href={'/ordenes-servicios/' + hashId} style={{ background: '#007bff', color: '#fff', padding: '4px 10px', marginLeft: '5px' }}>Servicios</a>
                          <a href={'/historico/' + hashId} style={{ background: '#007bff', color: '#fff', padding: '4px 10px', marginLeft: '5px' }}>Todos los documentos</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <TableFormasPagos
                  total={total}
                  loading={loading}
                  references={data.references}
                  handleOxxo={this.handleOxxo.bind(this)} />

              </div>

              <TableFacturas
                onChangeFacturas={this.onChangeFacturas.bind(this)}
                listChecks={listChecks}
                list={data.list}
                checkAll={checkAll}
                onChangeCheckAll={this.onChangeCheckAll.bind(this)}
                onClickDescargarPDF={this.onClickDescargarPDF.bind(this)}
                onClickDescargarReciboPDF={this.onClickDescargarReciboPDF.bind(this)}
                onClickDescargarXML={this.onClickDescargarXML.bind(this)}
              />

              <br />

              {
                total > 0 ?
                  <Fragment>
                    <div className="auto-container">
                      <div className="cart-outer">
                        <div className="row clearfix">
                          <div className="column col-lg-6 col-md-12 col-sm-12">
                          </div>
                          <div className="column col-lg-6 col-md-12 col-sm-12">
                            <ul className="totals-table">
                              <ItemConcepImports label="Total a Pagar" amount={total} />
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div className="text-center">
                      <button onClick={this.handleSelectTypePayment.bind(this, PREELABORADO)} type="button" className="theme-btn btn-style-one text-center centered disabled"><Icon icon="file" /> <i>Pagar</i></button>
                    </div>
                    <br />
                    {
                      typePayment === TARJETA ?
                        <CreditCard
                          {...this.state}
                          handleCallback={this.handleCallback}
                          handleInputChange={this.handleInputChange}
                          handleInputFocus={this.handleInputFocus}
                          handlePayment={this.handlePayment.bind(this)} /> : null
                    }
                  </Fragment>
                  : null
              }

              {visible ? <AlertDialog type={type} title={title} text={message} /> : null}
            </div>
          </div>
        </section>
      </Fragment>
    );
  }

  handleSelectTypePayment(typePayment) {
    this.setState({ typePayment });

    if (typePayment === PREELABORADO) {
      this.handlePaymentPreelaborado();
    }
  }

  handlePaymentPreelaborado() {
    console.log('handlePaymentPreelaborado');
    const { params: { hashId } } = this.props;
    const { total, listChecks } = this.state;

    const list = listChecks.map(c => {
      if (c.checked) {
        return c.id;
      }
    });

    postRequest({
      url: 'preelaborado',
      body: {
        total, list, hashId
      },
      success: ({ data: { url } }) => {
        window.open(url, '_blank');
      },
      fail: (error, status, data) => {
        console.log(status, data, error);
      }
    });
  }

  renderPaymentComplete() {
    const { payment } = this.state;

    return <PayCompleted payment={payment} />;
  }

  renderView() {
    const { paymentCompleted } = this.state;

    return paymentCompleted ? this.renderPaymentComplete() : this.renderPayment();
  }

  renderTemplate() {
    const { data, load } = this.state;

    return (
      <div>
        {data !== null ? this.renderView() : !load ? <LoadingProgress message="cargando" /> : null}

        {load && data === null ? <PageNotFound /> : null}
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderTemplate()}
      </Fragment>
    );
  }

}

Dashboard.propTypes = {
  params: PropTypes.object
};
