import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Label extends Component {

  render() {
    const { title, name, className } = this.props;

    const nameClass = className.match('control-label') ? className : `control-label ${className}`;

    return (
      <label htmlFor={name} className={nameClass}>{title}</label>
    );
  }

}

Label.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
};

Label.defaultProps = {
  name: '',
  className: 'control-label'
};
