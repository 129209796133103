import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'stateless';

export default class ErrorBoundary extends Component {

  constructor(props){
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    const { error, errorInfo  } = this.state;

    if ( errorInfo ) {
      return (
        <div className="container well">
          <h2><Icon icon="exclamation-triangle" /> Algo salió mal.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }

}

ErrorBoundary.propTypes = {
  children: PropTypes.node
};
