import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class OptionSelect extends Component {

  constructor(props){
    super(props);
  }

  renderOptions() {
    const { options } = this.props;

    return options.map((option, item) => {
      const value = typeof option.value !== 'undefined' ? { value: option.value } : '';

      return (
        <option
          key={item}
          {...value}>
          {option.label}
        </option>
      );
    });
  }

  render() {
    const { name, className, style, disabled, readOnly, selected, onChange, options } = this.props;

    const nameClass = className.match('form-control') ? className : `form-control ${className}`;

    return (
      <select
        id={name}
        name={name}
        ref={name}
        className={nameClass}
        style={style}
        onChange={onChange}
        disabled={disabled}
        defaultValue={ selected !== null ? options[selected].value : null }
        readOnly={readOnly}>

        {
          selected === null ? <option value=''>Seleccione</option> : null
        }

        {this.renderOptions()}
      </select>
    );
  }

}

OptionSelect.propTypes = {
  name:         PropTypes.string.isRequired,
  className:    PropTypes.string,
  style:        PropTypes.object,
  disabled:     PropTypes.bool,
  readOnly:     PropTypes.bool,
  onChange:     PropTypes.func,
  options:      PropTypes.array,
  selected:     PropTypes.number
};

OptionSelect.defaultProps = {
  className:  'form-control',
  disabled:   false,
  options:    [],
  selected:   null
};
