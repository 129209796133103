import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from 'stateless';
import { format } from 'utils/parseCost';
import { IconBrand } from 'components/cb';

export default class PayCompleted extends Component {

  constructor(props){
    super(props);
  }

  renderRows() {
    const { payment: { facturas } } = this.props;

    return facturas.map((r, i) => {
      const { serie, numero, saldo, fecha } = r;

      return (
        <tr key={i}>
          <td>{i+1}</td>
          <td>{serie} {numero}</td>
          <td>{moment(fecha).format('DD/MM/YYYY')}</td>
          <td>$ {format(saldo, 2)}</td>
        </tr>
      );
    });
  }

  render() {
    const { payment: { clave, amount, name, last4, brand } } = this.props;

    return (
      <section className="cart-section">
        <div className="auto-container">
          <div className="sec-title-2 light centered">
            <h2>PAGO PROCESADO CORRECTAMENTE <Icon icon='check' /></h2>
          </div>

          <div className="sec-title">
            <h3>Clave de pago: <strong><u>{clave}</u></strong></h3>

            <h3 style={{ marginTop: '20px' }}><Icon icon='money-check-alt' /> Pago por <strong>{amount}</strong></h3>
            <h3 style={{ marginTop: '20px' }}><Icon icon='id-card' /> A nombre de <strong>{name}</strong></h3>
            <h3><Icon icon='credit-card' /> Tarjeta con terminación <strong>{last4}</strong> <IconBrand brand={brand} /></h3>
          </div>

          <legend>Facturas pagadas</legend>

          <div className="table-outer">
            <table className="cart-table">
              <thead className="cart-header">
                <tr>
                  <th scope="col" width="5%">#</th>
                  <th scope="col">Número de Factura</th>
                  <th scope="col">Fecha</th>
                  <th scope="col">Importe</th>
                </tr>
              </thead>
              <tbody>
                {this.renderRows()}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
  }

}

PayCompleted.propTypes = {
  payment: PropTypes.object.isRequired
};
