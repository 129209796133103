import axios from 'axios';
import { API_KEY } from 'config/apiKey';

const DEV_URL        = `http://localhost:7012/api/`;
const PRODUCTION_URL = `https://apipagos.codigoblanco.mx/api/`;
console.log('process.env.NODE_ENV', process.env.NODE_ENV);
export const BASE_URL = process.env.NODE_ENV === 'production' ? PRODUCTION_URL : DEV_URL;
export const URL_FILES = BASE_URL.substring(0, BASE_URL.length - 4);

export const postRequest = (params = { fail: ()=> {}, success: ()=> {}, finally: ()=> {} }) => {
  if ( validateParameters(params) ) {
    axios
      .post(loadApi(params.url), params.body, {
        responseType: 'json',
        headers: {
          'User': localStorage.email
        }
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        console.error(error);
        params.fail(error.response.statusText, error.response.status, error.response.data);
      }).finally(()=> params.finally ? params.finally(): null );
  }
};

export const getRequest = (params = { fail: ()=> {}, success: ()=> {}, finally: ()=> {} }) => {
  if ( validateParameters(params) ) {
    axios
      .get(loadApi(params.url), params.body, {
        responseType: 'json',
        headers: {
          'User': localStorage.email
        }
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        console.error(error);
        params.fail(error.response.statusText, error.response.status, error.response.data);
      }).finally(()=> params.finally ? params.finally(): null );
  }
};


export const loadApi = url => {
  const segments = url.includes('?') ? `&` : `?`;

  return `${BASE_URL}${url}${segments}key=${API_KEY}`;
};

const validateParameters = (params) => {
  let successfully = true;

  if ( typeof(params.url) !== 'string' ) {
    successfully = false;
    throw 'url: undefined string';
  }

  if ( typeof(params.success) !== 'function' || typeof(params.fail) !== 'function' ) {
    successfully = false;
    throw 'undefined functions callback';
  }
  return successfully;
};

export const urlPhoto = () => {
  return BASE_URL.substring(0, BASE_URL.length - 4);
};

export const pathFilesApi = () => {
  return BASE_URL.substring(0, BASE_URL.length - 4);
};

export const imageExists = (imageUrl) => {
  var http = new XMLHttpRequest();

  http.open('HEAD', imageUrl, false);
  http.send();

  if ( http.status === 404 || http.status === 401 ) {
    return false;
  }
  return true;
};
