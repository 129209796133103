import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'utils/parseCost';

export default class CellAmount extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { amount, sign } = this.props;

    return (
      <span>
        { sign && amount > 0 ? '$ ' : null }
        { amount > 0 ? format(amount, 2) : null }
      </span>
    );
  }

}

CellAmount.propTypes = {
  sign: PropTypes.bool,
  amount: PropTypes.number.isRequired
};
