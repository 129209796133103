import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { App } from 'containers/';

import Dashboard from 'pages/Dashboard';
import Historicos from 'pages/Historicos';
import Login from 'pages/Login';
import PageNotFound from 'pages/PageNotFound';

export const RoutesApp = (
  <Route path='/' component={App} >
    <IndexRoute component={Login} />
    <Route exact path="/:hashId" component={Dashboard} />
    <Route exact path="/historico/:hashId" component={Historicos} />
    <Route path="*" component={PageNotFound} />
  </Route>
);
