import React, { Component } from 'react';
import { postRequest } from 'utils/api';

class Login extends Component {

  constructor(props){
    super(props);
    this.state = {
      error: false,
      message: '',
      razon_social: '',
      password: ''
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const { razon_social } = this.state;
    postRequest({
      url: 'login',
      body: { razon_social },
      success: ({ data }) => {
        location.href =`${data.hash}`;
      },
      fail: () => {
        this.setState({ error: true, message: 'Rázon social incorrecto'});
      }
    });
  }

  onChange(e){
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { razon_social } = this.state;
    return (
      <div className="container">
        <div className="row">
          <br />
          <div className="col-md-3 col-sm-1 col-xs-0"></div>
          <div className="col-md-6 col-sm-10 col-xs-12">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                  <br />
                  <br />
                  <img src="./img/logo.png" className="img-responsive" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8 col-sm-offset-2">
                  <form onSubmit={this.handleSubmit.bind(this)} className="col-sm-12 form-horizontal">
                    <br />
                    <br />
                    <div className="form-group ">
                      <div className="input-group">
                        <span className="input-group-addon" id="basic-addon1">
                        </span>
                        <input type="text" id="razon_social" name="razon_social" className="form-control" placeholder="Rázon Social"  value={razon_social} onChange={this.onChange.bind(this)} required />
                      </div>
                    </div>
                    {/* <div className="form-group ">
                      <div className="input-group">
                        <span className="input-group-addon" id="basic-addon1">
                        </span>
                        <input type="password" className="form-control " name="password" placeholder="Password" pattern=".{8,10}" maxLength="10" value={password} onChange={this.onChange.bind(this)} required />
                      </div>
                    </div> */}
                    <div className="form-group">
                      <button className="btn btn-home btn-block" type="submit">INICIAR SESIÓN</button>
                    </div>
                    {this.state.error &&
                      (<div className="alert alert-danger" role="alert">{this.state.message}</div>)}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </form>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xs-0"></div>
        </div>
      </div>
    );
  }
}

export default Login;
