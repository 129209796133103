import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DivFormGroup extends Component {

  render() {
    const { className, style, children } = this.props;

    const nameClass = className.match('form-group') ? className : `form-group ${className}`;

    return (
      <div className={nameClass} style={style}>
        {children}
      </div>
    );
  }

}

DivFormGroup.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired
};

DivFormGroup.defaultProps = {
  className: 'form-group'
};
