import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DlTD extends Component {

  render() {
    const { label, text, className, element } = this.props;

    return (
      <dl className={className}>
        <dt>{label}</dt>
        <dd>
          { typeof text === 'undefined' ? element : text }
        </dd>
      </dl>
    );
  }

}

DlTD.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  element: PropTypes.node,
  className: PropTypes.string
};
