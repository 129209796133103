import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-credit-cards';
import { Icon } from 'stateless';

export default class CreditCard extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { name, number, expiry, cvc, focused, issuer, paymentLoading,
      handleCallback, handleInputChange, handleInputFocus, handlePayment, email, phone } = this.props;

    return (
      <div className="row">
        <legend>Información de pago</legend>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <br />
          <br />
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={handleCallback}
            placeholders={{ name: 'NOMBRE' }}
            locale={{ valid: 'Expiración' }} 
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <form id="card-form" name="card-form" ref={c => (this.form = c)} onSubmit={handlePayment}>
            <div className="form-group">
              <input type="tel" name="number" className="form-control"
                maxLength="16"
                minLength="16"
                placeholder="Número de tarjeta" pattern="[\d| ]{16,22}" required
                onChange={handleInputChange} onFocus={handleInputFocus} />
              <small>ej.: 49..., 51..., 36..., 37...</small>
            </div>
            <div className="form-group">
              <input type="text" name="name" className="form-control"
                placeholder="Nombre del tarjetahabiente" required
                onChange={handleInputChange} onFocus={handleInputFocus} />
            </div>
            <div className="form-group">
              <input type="tel" name="phone" className="form-control"
                placeholder="Celular" required pattern="[0-9]{10}" maxLength={10}
                value={phone} onChange={handleInputChange} onFocus={handleInputFocus} />
            </div>
            <div className="form-group">
              <input type="email" name="email" className="form-control"
                placeholder="Correo electrónico" required
                value={email} onChange={handleInputChange} onFocus={handleInputFocus} />
            </div>
            <div className="row">
              <div className="col-4">
                <select placeholder="Mes expiración" name="month_expiry" className="form-control" onChange={handleInputChange} onFocus={handleInputFocus} required>
                  <option selected disabled>Mes expiración</option>
                  {
                    [...Array(12).keys()].map((x)=>{
                      const valor = (x+1).toString().padStart(2,'0');
                      return (
                        <option value={valor} key={x}>{valor}</option>
                      );
                    })
                  }
                </select>
              </div>
              <div className="col-4">
                <select placeholder="Año expiración" name="year_expiry" className="form-control" onChange={handleInputChange} onFocus={handleInputFocus} required>
                  <option selected disabled>Año expiración</option>
                  {
                    [...Array(12).keys()].map((x) => {
                      const anio = new Date().getFullYear().toString().substr(2,2);
                      const valor = (Number(anio) + x).toString().padStart(2,'0');
                      return (
                        <option value={valor} key={x}>{valor}</option>
                      );
                    })
                  }
                </select>
              </div>
              <div className="col-4">
                <input type="tel" name="cvc" className="form-control"
                  placeholder="CVC" pattern="\d{3,4}" required
                  maxLength="3"
                  minLength="3"
                  onChange={handleInputChange} onFocus={handleInputFocus} />
              </div>
            </div>
            <input type="hidden" name="issuer" value={issuer} />

            <br />
            <div className="form-actions">
              {
                paymentLoading ?
                  <div className="progress" style={{ marginTop:'30px', marginBottom:'30px' }}>
                    <div className="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                      aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width:'100%', backgroundColor:'#5cb85c' }}>
                      <span>Tu pago esta procesandose, favor de esperar</span>
                    </div>
                  </div>
                  :
                  <button type="submit" className="theme-btn btn-style-one text-center centered disabled"
                    disabled={paymentLoading}><Icon icon="credit-card" /> <i>Pagar</i></button>
              }
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CreditCard.propTypes = {
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  expiry: PropTypes.string.isRequired,
  cvc: PropTypes.string.isRequired,
  focused: PropTypes.string.isRequired,
  issuer: PropTypes.string.isRequired,
  paymentLoading: PropTypes.bool.isRequired,
  handleCallback: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleInputFocus: PropTypes.func.isRequired,
  handlePayment: PropTypes.func.isRequired,
};

