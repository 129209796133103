import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TextArea extends Component {

  render() {
    const { name, className, style, rows, cols, value, defaultValue, onChange } = this.props;

    return (
      <textarea
        id={name} name={name} ref={name}
        className={className}
        rows={rows}
        cols={cols}
        style={style}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}></textarea>
    );
  }

}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  rows: PropTypes.number,
  cols: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

TextArea.defaultProps = {
  className: 'form-control'
};
