import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InputEmail extends Component {

  render() {
    const { name, className, style, maxLength, placeholder, disabled, readOnly, value, defaultValue, onChange } = this.props;

    const nameClass = className.match('form-control') ? className : `form-control ${className}`;

    return (
      <input
        type="email" id={name} name={name} ref={name}
        className={nameClass}
        style={style}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        readOnly={readOnly} />
    );
  }

}

InputEmail.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

InputEmail.defaultProps = {
  className: 'form-control',
  disabled: false
};
