import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Header, Title, Body } from 'react-bootstrap';
import { DivFormGroup } from 'controls';
import { Icon } from 'stateless';

export default class ModalStores extends Component {

  constructor(props){
    super(props);
    this.state = {
      showModal: false
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  onChange(event) {
    const { name, checked } = event.target;

    if ( name === 'all' ) {
      const { stores } = this.props;

      stores.map(p => {
        if ( checked ) {
          this.frm[`${p._id}`].checked = true;
        }else{
          this.frm[`${p._id}`].checked = false;
        }
      });

      this.props.onSelectAllStores(checked);
    }
  }

  close(){
    const { stores } = this.props;

    this.setState({ showModal: false });

    const list = [];

    stores.map(p => {
      if ( this.frm[`${p._id}`].checked ) {
        list.push(p._id);
      }
    });

    this.props.selectStores(list);
  }

  open(){
    this.setState({ showModal: true });
  }

  render() {
    const { allStores, stores, listStores } = this.props;
    const { showModal } = this.state;

    return (
      <span>
        <button onClick={this.open} className="btn btn-default" type="button">
          <Icon icon="filter" /> Sucursales
        </button>

        <Modal bsSize="large" show={showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Seleccione las Sucursales</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form ref={(form) => { this.frm = form; }}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="checkbox pull-right">
                    <label>
                      <input name="all" id="all" type="checkbox" defaultChecked={allStores} onChange={this.onChange.bind(this)} />
                      <span style={{ marginLeft: '20px' }}><strong>Todos las Sucursales</strong></span>
                    </label>
                  </div>
                </div>

                {
                  stores.map((t, i) => {
                    const selected = listStores.filter(l => l === t._id);

                    return (
                      <DivFormGroup key={i} className="col-sm-4">
                        <label className="checkbox-inline">
                          <input type="checkbox" id={t._id} name={t._id} defaultChecked={ selected.length > 0 ? true : false } /> <i>{t.name}</i>
                        </label>
                      </DivFormGroup>
                    );
                  })
                }
              </div>
            </form>
            <div className="modal-footer">
              <button onClick={this.close} className="btn btn-default pull-right" type="button">Cerrar</button>
            </div>
          </Modal.Body>
        </Modal>
      </span>
    );
  }

}

ModalStores.propTypes = {
  allStores: PropTypes.bool.isRequired,
  stores: PropTypes.array.isRequired,
  listStores: PropTypes.array.isRequired,
  onSelectAllStores: PropTypes.func.isRequired,
  selectStores: PropTypes.func.isRequired
};
