import React from 'react';
import ReactDOM from 'react-dom';
import { Router, browserHistory } from 'react-router';
import { RoutesApp } from './routes';
ReactDOM.render(
  <Router history={browserHistory}>
    {RoutesApp}
  </Router>,
  document.getElementById('app')
);
