import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InputFileImage extends Component {

  constructor(props){
    super(props);
  }

  handleChange(event) {
    event.preventDefault();

    const { item, onChange } = this.props;

    if ( typeof onChange === 'function' ) {
      onChange(item);
    }
  }

  render() {
    const { name } = this.props;

    return (
      <input type="file"
        id={name}
        name={name}
        ref={name}
        onChange={this.handleChange.bind(this)}
        accept="image/jpg, image/jpeg, image/png" />
    );
  }

}

InputFileImage.propTypes = {
  name: PropTypes.string.isRequired,
  item: PropTypes.number,
  onChange: PropTypes.func
};
