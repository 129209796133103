import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Label from './Label';
import Input from './Input';

export default class InputLabel extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <div>
        <Label {...this.props} />
        <Input {...this.props} />
      </div>
    );
  }

}

InputLabel.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
