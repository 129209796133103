import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNumeric, isDecimal } from 'validator';

export default class InputNumber extends Component {

  render() {
    const { name, className, style, placeholder, value, onChange, min, max, step } = this.props;
    const nameClass = className.match('form-control') ? className : `form-control ${className}`;

    let defaultValue = null;

    if ( isDecimal(value + '') ) {
      defaultValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    }else if ( isNumeric(value + '') ) {
      defaultValue = isNaN(parseInt(value)) ? 0 : parseInt(value);
    }

    return (
      <input
        type="number"
        id={name}
        name={name}
        ref={name}
        className={nameClass}
        style={style}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        min={min}
        max={max}
        step={step} />
    );
  }

}

InputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.string
};

InputNumber.defaultProps = {
  className: 'form-control'
};
