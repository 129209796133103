import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IconBrand extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { brand } = this.props;
    let logo = '';

    if ( brand === 'visa' ) {
      logo = 'visa.png';
    }else if ( brand === 'american_express' ) {
      logo = 'americanexpress.png';
    }else if ( brand === 'mastercard' || brand === 'master_card' || brand === 'mc' ) {
      logo = 'mastercard.png';
    }

    return <img src={`./img/${logo}`} width="80px" alt="" className="img-responsive" />;
  }

}

IconBrand.propTypes = {
  brand: PropTypes.string.isRequired
};
