import React, { Component } from 'react';
import PropTypes from 'prop-types';

const STYLE_CONTAINER = {
  paddingTop:     '20px',
  paddingBottom:  '11px',
  paddingLeft:    '20px',
  paddingRight:   '20px'
};

export default class DivColorBG extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { text, color, style, className } = this.props;

    return (
      <div className={`bg-${color}`} style={STYLE_CONTAINER}>
        <p className={className} style={style}>{text}</p>
      </div>
    );
  }

}

DivColorBG.propTypes = {
  color: PropTypes.oneOf(['primary', 'success', 'info','warning', 'danger']),
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

DivColorBG.defaultProps = {
  color: 'primary'
};
