import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// import { Icon, Space } from 'stateless';
import { PUBLIC } from 'config/conekta';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from 'utils/payment';
import 'react-credit-cards/es/styles-compiled.css';
import { TableFacturasHistorico} from 'components/cb';


export default class Historico extends Component {

  constructor(props){
    super(props);
    this.state = {
      load: false,
      data: null,
      total: 0,
      phone: '',
      email: '',
      number: '',
      name: '',
      expiry: '',
      year_expiry: '',
      month_expiry: '',
      cvc: '',
      issuer: '',
      focused: '',
      error: {
        visible: false,
        type: '',
        title: '',
        message: ''
      },
      listChecks: [],

      paymentLoading: false,
      paymentCompleted: false,
      payment: {
        clave: '',
        facturas: [],
        name: '',
        last4: '',
        brand: '',
        amount: ''
      },
      typePayment: 0,
      loading: false
    };
    this.onChange = this.onChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount(){
    
  }

 

  onChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleCallback({ issuer }, isValid) {
    if (isValid) {
      this.setState({ issuer });
    }
  }

  handleInputFocus({ target }) {
    this.setState({
      focused: target.name,
    });
  }

  handleInputChange({ target }) {
    const name = target.name;
    let value = target.value;
    let { expiry } = this.state;
    const { year_expiry, month_expiry } = this.state;
    if (name === 'number') {
      value = formatCreditCardNumber(value);
    } else if (name === 'month_expiry') {
      expiry = formatExpirationDate(`${value.toString().padStart(2,'0')}${year_expiry}`);
    } else if (name === 'year_expiry') {
      expiry = formatExpirationDate(`${month_expiry.toString().padStart(2,'0')}${value}`);
    } else if (name === 'cvc') {
      value = formatCVC(target.value);
    }
    this.setState({ [name]: value, expiry });
  }

  onChangeFacturas({ item, checked }) {
    if ( ( item === 0 && ! checked ) || ( item > 0 && (checked || ! checked ) ) ) {
      const { listChecks, data: { list } } = this.state;

      listChecks.map((c, i) => {
        if ( checked || item === 0 ) {
          if ( i > item ) {
            c.checked = false;
          }else{
            c.checked = true;
          }
        }else{
          if ( i >= item ) {
            c.checked = false;
          }else{
            c.checked = true;
          }
        }
      });

      const total = list.reduce((count, factura) => {
        const facturaSelected = listChecks.find(c => c.id === factura._id && c.checked);
        if ( typeof facturaSelected !== 'undefined' ) {
          const unit_price = factura.saldo;
          if(factura.descuento_pago && factura.descuento_pago.percentage > 0){
            const descuento = (factura.descuento_pago.percentage / 100) * unit_price;
            count += (factura.saldo - descuento );
          } else {
            count += unit_price;
          }
        }
        return count;
      }, 0);

      this.setState({ listChecks, total });
    }
  }

  getTokenCard(callback) {
    const { name, number, expiry, cvc } = this.state;
    const e = expiry.split('/');
    const exp_year = parseInt(e[1]) + 2000;
    const exp_month = e[0];

    const card = { number, name, exp_year, exp_month, cvc };

    Conekta.setPublicKey(PUBLIC);

    Conekta.Token.create({ card }, (token) => {
      callback(true, token.id);
    }, (response) => {
      console.log('response', response);
      callback(false, null);
    });
  }

  cleanError() {
    this.setState({ error: { visible: false, type: '', title: '', message: '' } });
  }

  moved() {
    window.scrollTo(0, 0);
  }


  render() {
    const { params: { hashId } } = this.props;

    return (
      <Fragment>
        <section className="consult-section">
          <div className="auto-container">
            <div className="sec-title-2 light centered">
              <h2>HISTORICO</h2>
            </div>
          </div>
        </section>
        <section className="cart-section">
          <div className="form">
            <div className="auto-container">

              <TableFacturasHistorico hashId={hashId} notification={this.props.notification} />

              <br />

            </div>
          </div>
        </section>
      </Fragment>
    );
  }

}

Historico.propTypes = {
  params: PropTypes.object,
  notification: PropTypes.func,
};
