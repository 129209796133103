import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from 'stateless';

export default class RedirectTooltip extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { id, labelTooltip, url, icon, text, className } = this.props;

    const linkElement = typeof icon === 'string'
      ? (
        <Link to={url} className={className}>
          <Icon icon={icon} /> {text}
        </Link>
      ) : (
        <Link to={url} className={className}>{text}</Link>
      );

    return (
      <OverlayTrigger placement="top" overlay={ <Tooltip id={id}>{labelTooltip}</Tooltip> }>
        {linkElement}
      </OverlayTrigger>
    );
  }

}

RedirectTooltip.propTypes = {
  id: PropTypes.number.isRequired,
  labelTooltip: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string
};
