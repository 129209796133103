import React, { Component } from 'react';
import { Space } from 'stateless';

export default class Header extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <header className="main-header">
        <div className="header-top">
          <div className="auto-container clearfix">
            <div className="value-text" style={{fontSize: '14pt', fontWeight: '700'}}>C Ó D I G O<Space /><Space /><Space /><Space />B L A N C O</div>
          </div>
        </div>
        <div className="header-upper">
          <div className="inner-container">
            <div className="auto-container clearfix">
              <div className="logo-outer">
                <div className="logo">
                  <a href="/"><img src="/img/logo.png" alt="" title="" style={{ height:'140px' }} /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-header">
          <div className="auto-container clearfix">
            <div className="logo pull-left">
              <a href="/"><img src="/img/logo.png" alt="" title="" style={{ marginTop:'10px', marginBottom:'10px', height:'80px' }} /></a>
            </div>
          </div>
        </div>
      </header>
    );
  }

}
