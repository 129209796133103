import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RowsNotFound } from 'stateless';
import { format } from 'utils/parseCost';
import moment from 'moment';

export default class TableFacturas extends Component {

  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { name, checked } = event.target;

    const element = name.split('-');

    this.props.onChangeFacturas({
      item: parseInt(element[1]),
      id: element[2],
      checked
    });
  }


  renderRows() {
    const { list, listChecks } = this.props;

    return list.map((r, i) => {
      const { _id, serie, numero, importe, saldo, fecha, descuento_pago } = r;
      const abono = importe - saldo;
      let descuento = 0;
      if(descuento_pago && descuento_pago.percentage > 0){
        descuento = (descuento_pago.percentage / 100) * saldo;
      }
      const item = listChecks.find(c => c.id === r._id);
      const elementPeriod = `factura-${i}-${item.id}`;

      return (
        <tr key={i}>
          <td style={{textAlign: 'center'}}>
            <div className="custom-control custom-checkbox mr-sm-2">
              <input id={elementPeriod} name={elementPeriod} className="custom-control-input"
                type="checkbox" checked={item.checked} onChange={this.onChange} />
            </div>
          </td>
          <td>{i+1}</td>
          <td>
            {serie} {numero}
          </td>
          <td>{moment(fecha).format('DD/MM/YYYY')}</td>
          <td>$ {format(importe, 2)}</td>
          <td>$ {format(abono, 2)}</td>
          <td>$ {format(descuento, 2)}</td>
          <td>$ {format(saldo, 2)}</td>
          <td width="10%">
            <div className="btn-group">
              <button className="btn btn-primary btn-sm" onClick={ () => this.props.onClickDescargarPDF(_id) }>PDF</button>
              <button className="btn btn-primary btn-sm" onClick={ () => this.props.onClickDescargarXML(_id) }>XML</button>
            </div>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { list, checkAll, onChangeCheckAll } = this.props;
    const rows = list.length > 0 ? this.renderRows() : <RowsNotFound message="No cuenta con facturas por pagar en este momento." colSpan={7} />;
    return (
      <div className="table-outer">
        <table className="cart-table">
          <thead className="cart-header">
            <tr>
              <th scope="col" width="5%" style={{textAlign: 'center'}}>
                <div className="custom-control custom-checkbox mr-sm-2">
                  <input id="checkAll" name="checkAll" checked={checkAll} className="custom-control-input"
                    type="checkbox" onChange={onChangeCheckAll} />
                </div>
              </th>
              <th scope="col" width="5%">#</th>
              <th scope="col">Número de Factura</th>
              <th scope="col">Fecha</th>
              <th scope="col">Importe</th>
              <th scope="col">Abono en Garantía</th>
              <th scope="col">Descuento</th>
              <th scope="col">Saldo</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }

}

TableFacturas.propTypes = {
  list: PropTypes.array.isRequired,
  listChecks: PropTypes.array.isRequired,
  onChangeFacturas: PropTypes.func.isRequired,
  checkAll: PropTypes.bool.isRequired,
  onChangeCheckAll: PropTypes.func.isRequired,
  onClickDescargarPDF: PropTypes.func.isRequired,
  onClickDescargarXML: PropTypes.func.isRequired
};
