import React from 'react';
import PropTypes from 'prop-types';

export const Loading = () => {
  return (
    <div className="text-center">
      <span className="label label-success text-center">Cargando...</span>
    </div>
  );
};

export const Module = (props) => {
  const icon = typeof(props.icon) !== 'undefined' ? props.icon : '';
  const space = icon !== '' ? <Space /> : '';
  return (
    <div>
      <h1 className="page-header"><Icon icon={icon} />{space}{props.title}</h1>
      { props.children }
    </div>
  );
};

Module.defaultProps = {
  children: ''
};

Module.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export const SubModule = (props) => {
  const icon = typeof(props.icon) !== 'undefined' ? props.icon : '';
  const space = icon !== '' ? <Space /> : '';
  return (
    <div>
      <h1 className="page-header">{space}<small><Icon icon={icon} /> {props.title}</small></h1>
      { props.children }
    </div>
  );
};

SubModule.defaultProps = {
  children: ''
};

SubModule.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export const Icon = (props) => {
  const size = typeof props.size !== 'undefined' ? props.size : null;

  return (
    <i className={`fas fa-${props.icon}`} style={{fontSize: size}}></i>
  );
};

Icon.propTypes = {
  size: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export const DataNotFount = (props) => {

  const style = typeof props.style !== 'undefined' ? props.style : null;

  return (
    <div className="alert alert-info text-center" style={style} role="alert">
      {props.message}
    </div>
  );
};

DataNotFount.propTypes = {
  style: PropTypes.object,
  message: PropTypes.string
};

DataNotFount.defaultProps = {
  message: 'No se encontraron resultados.'
};

export const HR = () => <hr style={{ marginTop: '4px', marginBottom: '5px' }} />;

export const Space = (props) => {
  return (
    <span className={props.className}>&nbsp;</span>
  );
};

Space.propTypes = {
  className: PropTypes.string
};

export const Saving = (props) => {
  const action = typeof props.action === 'undefined' ? 'Guardando' : props.action;
  return (
    <span className="lead" style={{marginRight: '10px'}}>{action} favor de esperar...</span>
  );
};

Saving.propTypes = {
  action: PropTypes.string
};

export const TitlePanelDiets = (props) => {
  const styles = {
    marginBottom: '5px',
    marginTop: '5px'
  };

  return (
    <h3 style={styles} className="panel-title">{props.title}</h3>
  );
};

TitlePanelDiets.propTypes = {
  title: PropTypes.string
};

export const RowsNotFound = props => {
  return (
    <tr>
      <td colSpan={props.colSpan} className="text-center">{props.message}</td>
    </tr>
  );
};

RowsNotFound.propTypes = {
  colSpan: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired
};
