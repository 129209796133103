import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class LoadingProgress extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { message } = this.props;

    return (
      <div className="auto-container row">
        <div className="col-5"></div>
        <div className="col-2">
          <div className="progress" style={{ marginTop:'30px', marginBottom:'30px' }}>
            <div className="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width:'100%'}}>
              <span>{message}</span>
            </div>
          </div>
        </div>
        <div className="col-5"></div>
      </div>
    );
  }

}

LoadingProgress.propTypes = {
  message: PropTypes.string
};

LoadingProgress.defaultProps = {
  message: 'buscando...'
};
