import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RowConcept extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { text } = this.props;

    return (
      <div>
        <span className="lead">{text}</span>
      </div>
    );
  }

}

RowConcept.propTypes = {
  text: PropTypes.node.isRequired,
};
