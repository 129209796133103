import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'stateless';

export default class AlertDialog extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { title, text, type } = this.props;

    let color = '#161a33';
    let icon = 'circle';

    if ( type === 'info' ) {
      color = '#5bc0de';
      icon = 'info-circle';

    }else if (  type === 'danger' ) {
      color = '#d9534f';
      icon = 'times';

    }else if (  type === 'warning' ) {
      color = '#f0ad4e';
      icon = 'exclamation-triangle';
    }

    return (
      <div className="auto-container" style={{ paddingTop: '20px' }}>
        <div className="broucher-box">
          <div className="broucher-inner" style={{ backgroundColor: color }}>
            <div className="content">

              <div className="icon">
                <Icon icon={icon} />
              </div>

              <h3>{title}</h3>
              <div className="file" style={{ color: 'white' }}>{text}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

AlertDialog.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string
};

AlertDialog.defaultProps = {
  title: '',
  text: '',
  type: ''
};
