import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-bootstrap/lib/Pagination';

export default class Paginator extends Component {

  constructor(props){
    super(props);
    this.state = {
      active: 1
    };
    this.clickPagination = this.clickPagination.bind(this);
  }

  clickPagination(item) {
    const { pagination, callback, query } = this.props;
    this.setState({ active: item });

    const page = (item * pagination) - pagination;

    callback({ page, pagination, ...query });
  }

  renderPagination(){
    const { active } = this.state;
    const { data, total, pagination } = this.props;

    const pages = data.length > 0 ? Math.ceil(total / pagination) : 0;

    const firstPage = active > 2 ? active - 2 : 1;

    let lastPage = pages;

    if ( pages > 3 && (active+2) < pages ) {
      lastPage = active + 2;
    }

    const items = [];

    for (let page = firstPage; page <= lastPage; page++) {
      items.push(
        <Pagination.Item key={page} active={page === active} onClick={() => this.clickPagination(page)}>
          {page}
        </Pagination.Item>
      );
    }

    const prev = active > 1 ? active - 1 : 1;
    const next = active < pages ? active + 1 : pages;

    return (
      <div className="text-center">
        <Pagination>
          <Pagination.First onClick={() => this.clickPagination(1)} />
          <Pagination.Prev onClick={() => this.clickPagination(prev)} />
          {items}
          <Pagination.Next onClick={() => this.clickPagination(next)} />
          <Pagination.Last onClick={() => this.clickPagination(pages)} />
        </Pagination>
      </div>
    );
  }

  render() {
    return this.renderPagination();
  }

}

Paginator.propTypes = {
  pagination: PropTypes.number,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  query: PropTypes.object,
  callback: PropTypes.func.isRequired
};

Paginator.defaultProps = {
  pagination: 20
};


