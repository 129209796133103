import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'stateless';

import { isNumeric } from 'validator';

export default class NumberPicker extends Component {

  constructor(props){
    super(props);
    const { defaultValue } = this.props;

    this.state = {
      value: typeof defaultValue !== 'undefined' ? defaultValue : 0,
      message: ''
    };
  }

  onDecrease() {
    const { min } = this.props;
    const { value } = this.state;

    if ( typeof max === 'number' ) {
      if ( value > min ) {
        this.changeOriginalValue(value - 1);
      }
    }else{
      this.changeOriginalValue(value - 1);
    }
  }

  onIncrease() {
    const { max } = this.props;
    const { value } = this.state;

    if ( typeof min === 'number' ) {
      if ( value < max ) {
        this.isMore(value);
      }
    }else{
      this.isMore(value);
    }
  }

  isMore(value) {
    if ( isNaN(value) ) {
      this.changeOriginalValue(1);
    }else{
      this.changeOriginalValue(parseInt(value) + 1);
    }
  }

  onChange(event) {
    event.target.value.replace('.', null);

    this.changeOriginalValue(event.target.value.replace('.', null));
  }

  changeOriginalValue(valueOriginal) {
    const { min, max } = this.props;
    let message = '';

    if ( typeof min === 'number' && typeof max === 'number' ) {
      if ( max === Infinity ) {
        message = `min ${min}`;
      }else{
        message = `min ${min} y max ${max}`;
      }
    }

    if ( valueOriginal === null || valueOriginal === '' ) {
      this.setState({
        value: valueOriginal,
        message
      });
    }else{
      const value = parseInt(valueOriginal);

      if ( isNumeric(value + '') && ! isNaN(value) ) {
        if ( value >= min && value <= max  ) {
          this.setState({ value, message: '' });
        }else {
          this.setState({ message });
        }
      }
    }
  }

  onKeyUp(event) {
    this.setState({ value: event.target.value.replace('.', '') });
  }

  render() {
    const { name, size } = this.props;
    const { value, message } = this.state;

    let controlsSize = {};

    if ( typeof size !== 'undefined' && ( size === 'lg' || size === 'sm' ) ) {
      controlsSize = {
        btn:    `btn-${size}`,
        input:  `input-${size}`
      };
    }

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
          <div className="input-group">

            <span className="input-group-btn">
              <button
                onClick={this.onDecrease.bind(this)}
                className={`btn btn-danger ${controlsSize.btn}`} type="button">
                <Icon icon="minus-sign" />
              </button>
            </span>

            <input type="text"
              id={name}
              name={name}
              ref={(input) => { this.name = input; }}
              value={value}
              onChange={this.onChange.bind(this)}
              onKeyUp={this.onKeyUp.bind(this)}
              className={`form-control ${controlsSize.input} text-center`}
              autoComplete="off" />

            <span className="input-group-btn">
              <button
                onClick={this.onIncrease.bind(this)}
                className={`btn btn-success ${controlsSize.btn}`} type="button">
                <Icon icon="plus-sign" />
              </button>
            </span>

          </div>

          <div className="text-center">
            <small style={{color:'red'}}>{message}</small>
          </div>
        </div>
      </div>
    );
  }

}

NumberPicker.propTypes = {
  name:         PropTypes.string.isRequired,
  min:          PropTypes.number,
  max:          PropTypes.number,
  size:         PropTypes.string,
  onChange:     PropTypes.func,
  defaultValue: PropTypes.number
};

NumberPicker.defaultProps = {
  max:  Number.POSITIVE_INFINITY,
  size: ''
};
