import React, { Component } from 'react';

export default class PageNotFound extends Component {

  render(){
    return (
      <section className="error-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="title">Página no encontrada </div>
                <div className="text">No se encontro la página que solicitada.</div>
                <a href="https://www.codigoblanco.mx/" className="theme-btn btn-style-two"><i>Volver </i> <span className="fa fa-home"></span></a>
              </div>
            </div>
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="image">
                  <img src="images/resource/error-image.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

}
