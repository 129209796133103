import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'components/ErrorBoundary';


import { Header, Footer } from 'components/utils';
import NotificationSystem from 'react-notification-system';

export default class App extends Component {

  constructor(props){
    super(props);
    this.notification = this.notification.bind(this);
  }

  notification(params = {}) {
    if(!this.notifications)
      return setTimeout(this.notification.bind(this,params),1000);
    this.notifications.addNotification({
      title: params.title,
      message: params.message,
      level: params.type
    });
  }

  render() {
    return (
      <Fragment>
        <ErrorBoundary>
          <Header />
          { React.cloneElement(this.props.children, { notification: this.notification  }) }
          <Footer />
        </ErrorBoundary>
        <NotificationSystem ref={c => (this.notifications = c)} />
      </Fragment>
    );
  }
}

App.propTypes = {
  children: PropTypes.node,
};
